class ArByPhotoItem {
  $location:         any;
  appFactory:        any;
  assignmentFactory: any;
  loaderFactory:     any;
  notification:      any;
  errFactory:        any;

  live:    boolean;
  preprod: boolean;
  constructor ($location, AppFactory, AssignmentFactory, LoaderFactory, Notification, ErrFactory, Config) {
    Object.defineProperties(this, {
      $location:         { value: $location         },
      appFactory:        { value: AppFactory        },
      assignmentFactory: { value: AssignmentFactory },
      loaderFactory:     { value: LoaderFactory     },
      notification:      { value: Notification      },
      errFactory:        { value: ErrFactory        }
    });

    this.live    = Config.hostName && Config.hostName === 'live';
    this.preprod = Config.hostName && Config.hostName === 'preprod';
  }

  isDevice(): boolean {
    return !!window.cordova;
  }

  goToPhotoTN(): Promise<void> {
    if (!this.appFactory.isNetwork())                   return this.notification.alert({ title: 'errors.noInternetConnection', desc: 'errors.youHaveNoConnection' });
    if ((this.live || this.preprod) && !window.cordova) return this.notification.alert({ title: 'note', desc: 'timeTrackings.webviewerDisabled' });
    if ((this.live || this.preprod) && !window.cordova) return this.notification.alert({ title: 'note', desc: 'timeTrackings.webviewerDisabled' });

    this.loaderFactory.show();
    return this.assignmentFactory.getOwn()
      .then((jobs) => {
        if (jobs.length) return this.$location.path(`/activity-reports/phototn-activity-report`);
        else return Promise.reject(new this.errFactory.NoEBS);
      })
      .catch((err) => {
        if (err instanceof this.errFactory) err.notify();
        else console.error(err);
      })
      .then(() => this.loaderFactory.hide());
  }
}

window.app.component('arByPhotoItem', {
  template: require('scripts/components/time-trackings/ar-by-photo-item/ar-by-photo-item.html'),
  controller: ['$location', 'AppFactory', 'AssignmentFactory', 'LoaderFactory', 'Notification', 'ErrFactory', 'Config', ArByPhotoItem]
});
