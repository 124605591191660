class DailyReport {
  $scope:                        any;
  $location:                     any;
  $filter:                       any;
  $translate:                    any;
  $routeParams:                  any;
  $timeout:                      any;
  errFactory:                    any;
  validationErrorFactory:        any;
  timeTrackingFactory:           any;
  notification:                  any;
  loaderFactory:                 any;
  timeTrackingValidationService: any;

  timeTracking:  any;
  today:         string;
  deviceIsIOS:   boolean;
  dateFormat:    string;
  ttList:        any[];
  reloadTrigger: Date;
  statesTrigger: Date;

  errorList:          any[];
  displayErrors:      any[];
  displayErrorsTech:  any[];
  displayErrorsLegal: any[];
  displayErrorsAll:   any[];
  errorsClasses:      any;
  constructor ($scope: any, $location: any, $filter: any, $translate: any, $routeParams: any, $timeout: any, AssignmentFactory: any, AppFactory: any, ErrFactory: any, ValidationErrorFactory: any, TimeTrackingFactory: any, TimeTrackingValidationService: any, LoaderFactory: any, Notification: any) {
    Object.defineProperties(this, {
      $scope:                        { value: $scope                        },
      $location:                     { value: $location                     },
      $filter:                       { value: $filter                       },
      $translate:                    { value: $translate                    },
      $routeParams:                  { value: $routeParams                  },
      $timeout:                      { value: $timeout                      },
      errFactory:                    { value: ErrFactory                    },
      validationErrorFactory:        { value: ValidationErrorFactory        },
      timeTrackingFactory:           { value: TimeTrackingFactory           },
      notification:                  { value: Notification                  },
      loaderFactory:                 { value: LoaderFactory                 },
      timeTrackingValidationService: { value: TimeTrackingValidationService },
    });

    this.timeTracking    = null;
    this.today           = $filter('date')(new Date(), 'yyyy-MM-dd');
    this.deviceIsIOS     = deviceIsIOS;
    this.dateFormat      = localStorage.getItem('date_format') || 'dd.MM.yyyy';

    this.errorList       = [];
    this.displayErrors   = [];

    let passedTutorials  = JSON.parse(localStorage.getItem('passedTutorials')) || [];

    if (!passedTutorials.length || !passedTutorials.find(t => t === 'manual-tracking')) this.$location.path('/tutorial/manual-tracking');
    else {
      if (AppFactory.isNetwork()) {
        LoaderFactory.show();
        AssignmentFactory.sync()
        .then(() => this.loadTimeFramesList())
        .catch(err => {
          if (err instanceof this.errFactory) err.notify();
          else console.error(err);
        });
      } else this.loadTimeFramesList();
    }
  }

  get date() {
    return this.timeTracking && this.timeTracking.start_date;
  }

  set date(data: unknown) {
    if (typeof data === 'undefined') data = (document.getElementById('start-date') as HTMLInputElement).value;
    else if (data) this.timeTracking.start_date = data;
  }

  get startTime(): Date {
    return (this.timeTracking && this.timeTracking.start_date) || null;
  }

  set startTime(time) {
    if (time instanceof Date) this.timeTracking.start_date = time;
  }

  set endTime(time) {
    if (time instanceof Date) this.timeTracking.end_date = time;
  }

  get endTime(): Date {
    let end;

    if (this.timeTracking && this.timeTracking.start_date) end = this.timeTracking.start_date;
    else end = null;

    return (this.timeTracking && this.timeTracking.end_date) || end;
  }

  loadTimeFramesList(): Promise<void> {
    return Promise.resolve(this.timeTrackingFactory.getOwn())
    .then(list => this.ttList = list)
    .then(() => {
      let id = parseInt(this.$routeParams.id);
      if (id) this.updateDaily(id);
      else {
        this.timeTracking = { assignment: null };
        this.$scope.$apply();
      };
    })
    .then(() => this.loaderFactory.hide())
  };

  updateAssignment(assignment): void {
    let id = parseInt(this.$routeParams.id);
    this.timeTracking.assignment = assignment;
    if (assignment && !id) this.updateDaily(id);
    else this.validateDaily();
  }

  updateDaily(id: number): Promise<void> {
    return Promise.resolve()
    .then(() => {
      if (!Number.isNaN(id)) return this.openExistingDaily(id);
      else return this.createNewDaily();
    })
    .then(tt => this.timeTracking = tt)
    .then(() => this.validateDaily())
    .then(() => this.$scope.$apply());
  }

  openExistingDaily(id: number): Promise<any> {
    return Promise.resolve(this.timeTrackingFactory.getById(id));
  }

  createNewDaily() {
    return new this.timeTrackingFactory(this.defaultDaily(new Date()));
  }

  defaultDaily(start: Date) {
    return ({
      assignment: this.timeTracking.assignment,
      start_date: new Date(start.getFullYear(), start.getMonth(), start.getDate(),  8, 0, 0),
      end_date:   new Date(start.getFullYear(), start.getMonth(), start.getDate(), 16, 0, 0),
      pauses: [{
        start: new Date(start.getFullYear(), start.getMonth(), start.getDate(), 12,  0, 0),
        end:   new Date(start.getFullYear(), start.getMonth(), start.getDate(), 12, 30, 0),
      }]
    });
  }

  save(): Promise<void> {
    let self = this;
    return Promise.resolve(this.timeTracking.save())
    .then(() => this.notification.alert({
        title: 'activityReport.successfulSave',
        desc: 'activityReport.successfulSaveMessage'
      }, () => self.$scope.$apply(() => self.$location.path('/daily-report-list'))
    ))
    .catch(err => {
      if (err instanceof this.errFactory) return err.notify();
      else console.error(err);
    });
  }

  cancel(): void {
    this.$location.path('/daily-report-list');
  }

  remove(): Promise<void> {
    return this.notification.confirm_p({
      title: 'confirm',
      desc: 'questions.deleteDailyTimeTracking',
      cancelButton: 'no',
      button: 'yes'
    })
    .then(res => {
      if (res !== 2) return Promise.reject(new this.errFactory.QuietError);
    })
    .then(() => this.timeTracking.remove())
    .then(() => {
      this.$location.path('/daily-report-list');
      this.$scope.$apply();
    })
  }

  addPause(): void {
    if (this.timeTracking.start_date) {
      let dailyStart = this.timeTracking.pauses.length ? 
                       this.timeTracking.pauses[this.timeTracking.pauses.length - 1].end :
                       new Date(this.timeTracking.start_date.getTime() + (this.timeTracking.end_date - this.timeTracking.start_date) / 2);
      dailyStart.setSeconds(0);
      this.timeTracking.pauses.push(new this.timeTrackingFactory.Pause({
        start: new Date(+dailyStart),
        end:   new Date(+dailyStart + 30*60*1000),
      }, this.timeTracking));
      this.validateDaily();
    }
  }

  dateError(): boolean {
    return !!this.errorList.find(e => e instanceof this.validationErrorFactory.DailyError)
  }

  isArray(val: unknown): boolean {
    return Array.isArray(val);
  }

  validateDaily(): void {
    this.errorList           = [];
    this.timeTracking.errors = [];
    this.timeTracking.pauses.forEach(p => { p.errors = []; });

    if (this.timeTracking.start_date) {
      this.timeTracking.end_date.setDate(this.timeTracking.start_date.getDate());
      this.timeTracking.end_date.setMonth(this.timeTracking.start_date.getMonth());
      this.timeTracking.end_date.setFullYear(this.timeTracking.start_date.getFullYear());
      if (this.timeTracking.end_date.getTime() < this.timeTracking.start_date.getTime()) this.timeTracking.end_date.setDate(this.timeTracking.end_date.getDate() + 1);
    }

    this.reloadTrigger = new Date();

    this.$timeout(() => {
      this.timeTrackingValidationService.validateDaily(this.timeTracking, this.ttList);

      this.errorList = [...this.timeTracking.errors];
      this.timeTracking.pauses.forEach(p => { this.errorList = [...this.errorList, ...p.errors]; });
    
      this.displayErrorsTech  = [...new Set(this.errorList.filter(err => err instanceof this.validationErrorFactory.TechnicalError).map(err => err.messageOptions ? [err.message, err.messageOptions] : err.message))];
      this.displayErrorsLegal = [...new Set(this.errorList.filter(err => err instanceof this.validationErrorFactory.LegalError).map(err => err.message))];
      this.displayErrorsAll   = [...this.displayErrorsTech, ...this.displayErrorsLegal];
      this.getErrorClass();
      this.statesTrigger = new Date();
    });
  }

  passValidation(callback: Function): Promise<void> {
    if (this.displayErrorsAll.length) {
      if (this.displayErrorsTech.length) return Promise.all(this.displayErrorsAll.map(e => Array.isArray(e) ? this.$translate(...e) : this.$translate(e))).then(t => {
        this.notification.alert({
          title: 'activityReport.successfulSave',
          desc: Object.values(t).map(e => `- ${e};`).join('\n')
        });
      });

      if (this.displayErrorsLegal.length) return this.$translate([...this.displayErrorsLegal, 'timeTrackings.saveAnyway']).then(t => {
        this.notification.confirm_p({
          title: 'timeTrackings.workingHoursAct',
          desc: Object.values(t).splice(-Object.values(t).length, Object.values(t).length - 1).map(e => `- ${e};`).join('\n') + `\n\n${t['timeTrackings.saveAnyway']}`,
          cancelButton: 'no',
          button: 'yes'
        }).then(res => {
          if (res === 2) callback.apply(this);
        });
      });
    } else callback.apply(this);
  }

  private getErrorClass(): void {
    this.errorsClasses = {};
    if (this.timeTracking.errors && this.timeTracking.errors.length) {
      let fullDailyErrors = this.timeTracking.errors.filter(e =>  !e.dailyStart && !e.dailyEnd);
      if (fullDailyErrors.length) {
        this.errorsClasses.techErr  = fullDailyErrors.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        this.errorsClasses.legalErr = fullDailyErrors.find(err => err instanceof this.validationErrorFactory.LegalError);
      }
      
      let startDailyError = this.timeTracking.errors.filter(e => e.dailyStart);
      if (startDailyError.length) {
        this.errorsClasses.techErrStart  = startDailyError.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        this.errorsClasses.legalErrStart = startDailyError.find(err => err instanceof this.validationErrorFactory.LegalError);
      }

      let endDailyError = this.timeTracking.errors.filter(e => e.dailyEnd);
      if (endDailyError.length) {
        this.errorsClasses.techErrEnd  = endDailyError.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        this.errorsClasses.legalErrEnd = endDailyError.find(err => err instanceof this.validationErrorFactory.LegalError);
      }
    }
  }

}

window.app.component('dailyReport', {
  template: require('scripts/components/time-trackings/daily-report/daily-report.html'),
  controller: ['$scope', '$location', '$filter', '$translate', '$routeParams', '$timeout', 'AssignmentFactory', 'AppFactory', 'ErrFactory', 'ValidationErrorFactory', 'TimeTrackingFactory', 'TimeTrackingValidationService', 'LoaderFactory', 'Notification', DailyReport]
});
