class DailyReportList {
  $scope:                any;
  $rootScope:            any;
  $location:             any;
  assignmentFactory:     any;
  loaderFactory:         any;
  errFactory:            any;

  dateFormat:          string;
  dateWithWeek:        string;
  column:              string;

  ttList:              any[];
  activityReportsList: any[];
  constructor ($scope, $rootScope, $location, ActivityReportFactory, AssignmentFactory, TimeTrackingFactory, LoaderFactory, ErrFactory) {
    Object.defineProperties(this, {
      $scope:                { value: $scope                },
      $location:             { value: $location             },
      $rootScope:            { value: $rootScope            },
      assignmentFactory:     { value: AssignmentFactory     },
      loaderFactory:         { value: LoaderFactory         },
      errFactory:            { value: ErrFactory            },
    });

    this.column                 = 'start_date';
    this.$scope.reverse         = true;
    this.$scope.reverseCustomer = false;
    this.$scope.reverseDate     = false;

    this.dateFormat   = localStorage.getItem('date_format') || 'dd.MM.yyyy';
    this.dateWithWeek = `EEE.${this.dateFormat}`;

    this.ttList = [];

    Promise.all([
      TimeTrackingFactory.getOwn()
      .then((list) => this.ttList = list)
      .catch((err) => console.error(err)),

      ActivityReportFactory.getOwn()
      .then((list) => this.activityReportsList = list.filter((ar) => ar.readyToSubmit))
      .catch((err) => console.error(err))
    ])
    .then(() => $scope.$apply());

    this.addListener();
  }

  private addListener(): void {
    this.$scope.$on('$locationChangeSuccess', (event, newUrl, previousUrl) => {
      if (newUrl.includes('/activity-report/preselected')) 
        this.$rootScope.ARbackLocation = previousUrl.substring(previousUrl.indexOf("#") + 1);
    });
  }

  changeLocation(to: string) {
    return this.$location.path(to);
  }

  goToNewDailyReport(): Promise<void> {
    this.loaderFactory.show();
    return this.assignmentFactory.getOwn()
      .then((jobs) => {
        if (jobs.length) return this.$location.path(`/daily-report/new`);
        else return Promise.reject(new this.errFactory.NoEBS)
      })
      .catch((err) => {
        if (err instanceof this.errFactory) err.notify();
        else console.error(err);
      })
      .then(() => this.loaderFactory.hide())
  }

  viewReport(tf) {
    if (tf.status === 1 || tf.status === 2) {
      return this.$location.path(`/time-tracking/${tf.id}`);
    } else {
      return this.$location.path(`/daily-report/${tf.id}`);
    }
  }

  editReport(event, tf) {
    event.stopPropagation();
    return this.$location.path(`/daily-report/${tf.id}`);
  }

  openActivityReport (ar) {
    return this.$location.path(`/activity-report/${ar.id}/confirm`);
  }

  sortColumn (col) {
    if (col === 'customer') {
      this.column = 'assignment.title';
      this.$scope.reverseCustomer = !this.$scope.reverseCustomer;
    }
    else if (col === 'date') {
      this.column = 'start_date';
      this.$scope.reverseDate = !this.$scope.reverseDate;
    }
    return this.$scope.reverse = !this.$scope.reverse;
  }

}

window.app.component('dailyReportList', {
  template: require('scripts/components/time-trackings/daily-report-list/daily-report-list.html'),
  controller: ['$scope', '$rootScope', '$location', 'ActivityReportFactory', 'AssignmentFactory', 'TimeTrackingFactory', 'LoaderFactory', 'ErrFactory', DailyReportList]
});
